import React, { useEffect, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import { CenterOnMobile, ImageWrapper, Spacer } from "../components/styles";
import { Container, Grid, GridContainer, TwoColumnLayout } from "ui-start";

const IndexPage = () => {
  const [imageOrder, setImageOrder] = useState(1);
  const [descOrder, setDescOrder] = useState(2);

  useEffect(() => {
    if (window.innerWidth < 601) {
      setImageOrder(2);
      setDescOrder(1);
    }
  }, []);

  const content = (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          googleSheetHomeRow {
            heroimage01
            heroimage02
            heroimage03
            description
            title
          }
        }
      `}
      render={({
        googleSheetHomeRow: {
          title,
          description,
          heroimage01,
          heroimage02,
          heroimage03,
        },
      }) => (
        <>
          <CenterOnMobile>
            <Grid columns={1} wrap>
              <GridContainer order={imageOrder}>
                <Grid columns={3} mobileColumns={1} wrap>
                  <Container
                    border="large"
                    borderColor="white"
                    horizontalCenterMobile
                  >
                    <ImageWrapper
                      src={heroimage01}
                      imgWidth="100%"
                      mobWidth="80%"
                    />
                  </Container>
                  <Container
                    border="large"
                    borderColor="white"
                    centerHorizontal
                  >
                    <ImageWrapper
                      src={heroimage02}
                      imgWidth="auto"
                      mobWidth="80%"
                      imgHeight="270px"
                    />
                  </Container>
                  <Container
                    border="large"
                    borderColor="white"
                    horizontalCenterMobile
                  >
                    <ImageWrapper
                      src={heroimage03}
                      imgWidth="100%"
                      mobWidth="80%"
                    />
                  </Container>
                </Grid>
              </GridContainer>
              <GridContainer order={descOrder}>
                <Container centerHorizontal>
                  <p dangerouslySetInnerHTML={{ __html: description }} />{" "}
                </Container>
                <Spacer size={24} />
              </GridContainer>
              <Spacer size={16} />
            </Grid>
          </CenterOnMobile>
        </>
      )}
    />
  );

  return (
    <Layout>
      <Spacer height={24} />
      <TwoColumnLayout
        leftFlexBasis={20}
        rightFlexBasis={80}
        title="Creative content Communications Production"
        headerFontSize={24}
        mobileHeaderFontSize={35}
        titleLetterSpacing={2}
      >
        <Spacer height={6} />
        {content}
      </TwoColumnLayout>
    </Layout>
  );
};

export default IndexPage;
